import {Injectable} from '@angular/core';
import {BehaviorSubject} from "rxjs";

@Injectable({ providedIn: 'root' })
export class DateService {

    _selectedYear = new BehaviorSubject<number|null>(null);

    // todo более правильно было бы эти данные перенести в filterService
    get selectedYear() {
        return this._selectedYear.value;
    }

    set selectedYear(value: number) {
        this._selectedYear.next(value);
    }

  getYearList() {
    const list = [];
    for (let year = 2017; year <= this.getCurrentYear()+1; year++) {
      list.push(year);
    }
    return list;
  }

  getYearObjectList() {
    return this.getYearList().map((year) => {
      return {
        label: year,
        value: year
      };
    });
  }

  getCurrentYear() {
    return new Date().getFullYear();
  }

  getMonthList() {
    const months = [
      'январь',
      'февраль',
      'март',
      'апрель',
      'май',
      'июнь',
      'июль',
      'август',
      'сентябрь',
      'октябрь',
      'ноябрь',
      'декабрь'
    ];
    return months;
  }

  getMonthObjectList(short = false) {
    return this.getMonthList().map((month, index) => {
      return {
        label: short ? month.substr(0, 3) : month,
        value: index + 1
      };
    });
  }

  getCurrentMonthNumber() {
    return new Date().getMonth() + 1;
  }

  getMonthName(number?: number, short = false) {
    if (!number) {
      number = this.getCurrentMonthNumber();
    }
    let monthName = this.getMonthList()[number - 1];
    if (short) {
      monthName = monthName.substr(0, 3);
    }
    return monthName;
  }
}
