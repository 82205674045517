import { BehaviorSubject } from "rxjs";
import * as i0 from "@angular/core";
var DateService = /** @class */ (function () {
    function DateService() {
        this._selectedYear = new BehaviorSubject(null);
    }
    Object.defineProperty(DateService.prototype, "selectedYear", {
        // todo более правильно было бы эти данные перенести в filterService
        get: function () {
            return this._selectedYear.value;
        },
        set: function (value) {
            this._selectedYear.next(value);
        },
        enumerable: true,
        configurable: true
    });
    DateService.prototype.getYearList = function () {
        var list = [];
        for (var year = 2017; year <= this.getCurrentYear() + 1; year++) {
            list.push(year);
        }
        return list;
    };
    DateService.prototype.getYearObjectList = function () {
        return this.getYearList().map(function (year) {
            return {
                label: year,
                value: year
            };
        });
    };
    DateService.prototype.getCurrentYear = function () {
        return new Date().getFullYear();
    };
    DateService.prototype.getMonthList = function () {
        var months = [
            'январь',
            'февраль',
            'март',
            'апрель',
            'май',
            'июнь',
            'июль',
            'август',
            'сентябрь',
            'октябрь',
            'ноябрь',
            'декабрь'
        ];
        return months;
    };
    DateService.prototype.getMonthObjectList = function (short) {
        if (short === void 0) { short = false; }
        return this.getMonthList().map(function (month, index) {
            return {
                label: short ? month.substr(0, 3) : month,
                value: index + 1
            };
        });
    };
    DateService.prototype.getCurrentMonthNumber = function () {
        return new Date().getMonth() + 1;
    };
    DateService.prototype.getMonthName = function (number, short) {
        if (short === void 0) { short = false; }
        if (!number) {
            number = this.getCurrentMonthNumber();
        }
        var monthName = this.getMonthList()[number - 1];
        if (short) {
            monthName = monthName.substr(0, 3);
        }
        return monthName;
    };
    DateService.ngInjectableDef = i0.defineInjectable({ factory: function DateService_Factory() { return new DateService(); }, token: DateService, providedIn: "root" });
    return DateService;
}());
export { DateService };
